import React, {useEffect} from 'react';
import {fontObserver} from '../helper/font';
import Page from '../components/page';
import Header from '../components/header';
import {ContentSection} from '../components/section';
import {H2, P} from '../components/webComponents';
import Footer from '../components/footer';
import SeoHeader from '../components/seoHeader';

const Error = () => {
  useEffect(() => {
    fontObserver();
  }, []);
  return (
    <Page>
      <SeoHeader
        title={'Fehler'}
        description={'Die angeforderte Seite konnte lieder nicht gefunden werden.'}
        image={`${require('../public/static/assets/error.jpg?resize&size=600')}`}
      />
      <Header
        title={'Fehler'}
        description={'Die angeforderte Seite konnte lieder nicht gefunden werden.'}
        placeholderImage={`${require('../public/static/assets/error.jpg?resize&size=10')}`}
        largeImage={`${require('../public/static/assets/error.jpg?resize&size=1000')}`}
      />
      <ContentSection>
        <H2>Fehler</H2>
        <P>Die angeforderte Seite konnte lieder nicht gefunden werden.</P>
      </ContentSection>
      <Footer />
    </Page>
  );
};

export default Error;
